import React, {useState, useEffect, useRef} from "react";
import { useSelector} from 'react-redux';
import { useLocation } from "react-router-dom";
import {SetMetaData} from './../../helper/page-title';
import Select from 'react-select';
import './../../assets/css/pages/team.css';
import {get_api_call, post_api_call, error_api_call} from '../../actions/action-creators/common';
import {alertResponseMessage} from '../../actions/action-creators/response';
import { RSstandardStyle } from "../../config/react-select";
import {toggleBodyLoader, numShortForm} from '../../helper/custom-function';
import {LoaderMedium} from './../../components/loader';
import ModalSharePort from "../../components/modal/portfolio/share-port";
import noStuImg from '../../assets/images/oops.png';
import {ShareSvg, HeartSvg, EyeSvg, PushPinSvg} from './../../components/ui/svg';
// import HubListScrollComp from "../../components/hub/list-scroll";
const selectOptionLabel = {school_id: 'school_name', class_id: 'class_name', skill_id: 'name'};

function PortfolioMain(props){
    SetMetaData("PORT – Student Portfolio Management System", "", "");
    //Check if Logged In
    var userReduxData = useSelector(state => state.auth);
    userReduxData = userReduxData && userReduxData.user_info ? JSON.parse(userReduxData.user_info) : null;
    //Get Portfolio Details
    // const fetchPortfolioData = async() => {
    //     try{
    //         const portfolioApiData = await get_api_call(`portfolio/basic-details`, {});
    //         if(portfolioApiData.status === 200){
    //             if(portfolioApiData.data.headline){
    //                 window.location.href = `/port/${portfolioApiData.data.user_name}`;
    //             }else{
    //                 window.location.href = `/port/edit`;
    //             }
    //             setPageLoader(false);
    //         }
    //     }catch(e){
    //         console.log(e);
    //         alertResponseMessage({alert_type: 2, message: "Error Loading Portfolio Details! Check back after some times"});
    //         error_api_call({error: `portfolio/landing|fetchPortfolioData|${e}`});
    //     }
    // }
    // useEffect(() => {
    //     fetchPortfolioData();
	// }, []);
    const location = useLocation();
    const [classData, setClassData] = useState([]);
    const [schoolData, setSchoolData] = useState([]);
    const [passionData, setPassionData] = useState([]);
    const [filterData, setFilterData] = useState({name: '', school_id: '', class_id: '', skill_id: ''});
    const [studentData, setStudentData] = useState([]);
    const [studentPage, setStudentPage] = useState(0);
    const [callStuAPI, setCallStuAPI] = useState(0);
    const [mPortfolioData, setPortfolioData] = useState({});
    const [ownerPortData, setOwnerPortData] = useState({});
    const [listedDataState, setListedData] = useState({});
	//Loading
	const [showStuLoader, setStuLoader] = useState(true);
	const [studentLoading, setFellowLoading] = useState(false);
	const [pageLoading, setPageLoading] = useState(true);
	const limit = 30;
	const loadMoreFellow= useRef(null);//Button Click
	const studentLoader = useRef(null);
    //Popup
    const[stylePop, setstylePop] = useState({});
    const [modalToggle, setModalToggle] = useState({share: false});
    // Update Filter Input
    const updateInput = (input_type, field, value, select_option='') => {
        try{
            if(field === 'name'){
                value = value.replace(/[^a-zA-Z. ]/g, '')
                            .replace('  ', ' ');
            }
            const filledFilterData = {...filterData, [field]: value};
            // setFilterData({...filterData, [field]: value});
            setFilterData({...filledFilterData});
            if(field !== 'name'){
                // searchPortfolio();
                // setStudentPage(1);
                toggleBodyLoader('load');
                if(filledFilterData[field].id === ''){
                    modifyAdBarUrl(field, '');
                }else{
                    modifyAdBarUrl(field, `${filledFilterData[field].id},${filledFilterData[field][select_option]}`);
                }
                setPageLoading(true);
                setStudentPage(studentPage === 1 ? 0 : 1);
            }
        }catch(e){
            alertResponseMessage({alert_type: 2, message: "Error Loading Portfolio Details! Check back after some times"});
            // console.log(e)
            error_api_call({error: `portfolio/landing|updateInput|${e}`});
            return false;
        }
    }
    //Search by Filter Data
    const searchPortfolio = (e) => {
        try{
            e.preventDefault();
            toggleBodyLoader('load');
            modifyAdBarUrl('name', filterData.name);
            setPageLoading(true);
            setStudentPage(studentPage === 1 ? 0 : 1);
        }catch(e){
            alertResponseMessage({alert_type: 2, message: "Error Loading Portfolio Details! Check back after some times"});
            // console.log(e)
            error_api_call({error: `portfolio/landing|searchPortfolio|${e}`});
        }
    }
    //Get Data for Country & Program
    const fetchSelectList = async(type, url) => {
        try{
            const selectApiData = await get_api_call(url, {});
            var data = [];
            if(selectApiData.status === 200){
                data = selectApiData.data;
                switch (type) {
                    case 'school':
                        setSchoolData([...data]);
                        break;
                    case 'classes':
                        setClassData([...data, ...selectApiData.course_data]);
                        break;
                    case 'passion':
                        setPassionData([...data]);
                        break;
                    default: 
                }
            }
            return {
                data
            };
        }catch(e){
            error_api_call({error: `portfolio|landing|fetchSelectList|${e}`});
            return {data: []};
        }
    }
    //Modify URL
	const modifyAdBarUrl = (key, value) => {
		try{
			// if(typeof value === 'array'){
			// if(value instanceof Array){
				const url = new URL(window.location.href);
				if(!value){//value.length === 0
					url.searchParams.delete(key);
				}else{
					url.searchParams.set(key, value);
				}
				
				window.history.pushState({}, '', url.toString());
			// }
		}catch(e){
			// console.log(e)
			error_api_call({error: `portfolio/landing|modifyAdBarUrl|${e}`});
			return false;
        }
	};
	//Load More Data when loader is in focus
	const infiniteCustomScroll = () => {
		if(loadMoreFellow.current){
			loadMoreFellow.current.click();
		}
	}
    //Timer to suffle
    useEffect(() => {
        const timeout = setInterval(() => {
            // if(localStorage.getItem("token") && userReduxData){
                portShuffle();
            // }
          console.log('adada')
        }, 30 * 60 * 1000);
    
        return () => clearInterval(timeout);
      }, []);// eslint-disable-line react-hooks/exhaustive-deps
	//Initial Load & Read More Implementation
	useEffect(() => {
        const options = {
           root: null,
           rootMargin: "20px",
           threshold: 1.0
        };
       // initialize IntersectionObserver
       // and attaching to Load More div
        //For All
		const observerAll = new IntersectionObserver(infiniteCustomScroll, options);
        if (studentLoader.current) {
            observerAll.observe(studentLoader.current)
        }
		const clean = studentLoader.current;
        return () => {
            if (clean) {
                observerAll.disconnect(clean);
            }
        }
    }, [studentLoader.current]);// eslint-disable-line react-hooks/exhaustive-deps
    //Call Filter Data
	useEffect(() => {
		const urlHandling = () => {
			try{
				//URL Param type Handling
				const urlQuryString = location.search;
				const urlParam = new URLSearchParams(urlQuryString);
				if(urlParam.size > 0){
					// const filterKeys = ['name', 'status', 'year', 'program', 'sector', 'country'];
                    const filterKeys = ['name', 'school_id', 'class_id', 'skill_id'];
					var filterDataNew = {...filterData};
					urlParam.forEach((value, key) => {
						if(filterKeys.indexOf(key) > -1){
                            if(key !== 'name'){
                                var urlParamData = value.split(',');
                                filterDataNew[key] = {id: parseInt(urlParamData[0]), [selectOptionLabel[key]]: urlParamData[1]};
                            }else{
                                filterDataNew[key] = value;
                            }
						}
					});
					// setFilterData({...filterDataNew});
                    // setTimeout(() => {
                        setFilterData({...filterDataNew});
                    // }, 13000)
                    
				}
				setCallStuAPI(1);
				setStudentPage(studentPage === 1 ? 0 : 1);
			}catch(e){
				alertResponseMessage({alert_type: 2, message: "Error Loading Portfolios! Check back after some times"});
				error_api_call({error: `portfolio/landing|urlHandling|${e}`});
			}
		}
        const fetchOwnerData = async() => {
            try{
                const ownerApiData = await post_api_call('portfolio-details', {});
                if(ownerApiData.status === 200){                    
                    setOwnerPortData(ownerApiData.data);
                }
            }catch(e){
				// alertResponseMessage({alert_type: 2, message: "Error Loading Portfolio Details! Check back after some times"});
				error_api_call({error: `portfolio/landing|fetchOwnerData|${e}`});
			}
        }
        fetchOwnerData();
        fetchSelectList('passion', 'passion-scores?fetch_type=2');
        fetchSelectList('school', 'schools');
        fetchSelectList('classes', 'classes');
        urlHandling();
	}, []);// eslint-disable-line react-hooks/exhaustive-deps
    //Call Fellow Filter API
	useEffect(() => {
		//Get Filtered Fellow
		const fetchData = async() => {
			try{
				//APi Call
				if(!studentLoading){
					setFellowLoading(true);
					const stuCurrentPage = studentPage < 2 ? 1 : studentPage;
					if(stuCurrentPage === 1){
						setStudentData([]);
					}
                    const filterPayload = {};
                    const filterKeys = ['name', 'skill_id', 'school_id', 'class_id'];
                    filterKeys.forEach(filterKey => {
                        if(filterData[filterKey]){
                            if(filterKey === 'name'){
                                filterPayload[filterKey] = filterData[filterKey];
                            }else if(typeof filterData[filterKey].id !== "undefined"){
                                filterPayload[filterKey] = filterData[filterKey].id;
                            }
                        }
                    });
					const newFellowList = await post_api_call('portfolio', 
																{limit,
																page: stuCurrentPage,
                                                                filter: filterPayload
																// filter: {name: filterData.name,
                                                                //         skill_id: filterData.skill_id !== '' ? filterData.skill_id.id : '',
                                                                //         school_id: filterData.school_id !== '' ? filterData.school_id.id : '',
                                                                //         class_id: filterData.class_id !== '' ? filterData.class_id.id : ''},
																// keyword: queryKeyword,
															});
					if(newFellowList.status === 200){
						const newFellowData = stuCurrentPage < 2
												? newFellowList.data 
												: studentData.concat(newFellowList.data);
                        // if(stuCurrentPage === 1){
                            // portShuffle(newFellowData);
                        // }
						setStudentData(newFellowData);
						if(stuCurrentPage === 1){
							// setFellowTotal(newFellowList.total);
							setPageLoading(false);
						}
                        var dataIntState = {...listedDataState};
                        newFellowList.data.forEach(stu_data => {
                            dataIntState[stu_data.id] = {loader: false,
                                                        like_count: stu_data.port_like_count||0,
                                                        self_like: stu_data.self_like
                                                    };
                        });
                        setListedData({...dataIntState});
					}
					setFellowLoading(false);
					toggleLoader(stuCurrentPage, newFellowList.total);
					if(stuCurrentPage === 1){
						toggleBodyLoader('remove');
					}
				}
			}catch(e){
				alertResponseMessage({alert_type: 2, message: "Error Loading Portfolio Details! Check back after some times"});
				// console.log(e);
				setFellowLoading(false);
				error_api_call({error: `portfolio/landing|fetchData|${e}`});
			}
		}
		if(callStuAPI === 1){
			fetchData();
		}
	}, [studentPage]);// eslint-disable-line react-hooks/exhaustive-deps
	//Load More Fellow
	const loadMore = () => {
        if(!studentLoading){
		    setStudentPage(studentPage < 2 ? 2 : studentPage+1);
        }
	}
	//Show-Hide Item Loader
	const toggleLoader = (current_page, total_page) => {
        try{
            var response = {page_count: 0, is_show: false};
			const totalPage = total_page <= limit
							? 1
							: total_page % limit === 0 
							? total_page/limit 
							: parseInt((total_page/limit))+1;
							// console.log(current_page, 'current_page')
							// console.log(totalPage, 'totalPage')
			if(current_page < totalPage){
				response.is_show = true;
				setStuLoader(true);
			}else{
				setStuLoader(false);
			}
			response.page_count = totalPage;
            return response;
        }catch(e){
            return response;
        }
    }
    //Show-Hide Popup
    const getPopupPos = (e, state, data={}) => {
        try{
            const element = e.target.getBoundingClientRect();
            const tt =  {
                top: element.top + window.pageYOffset,
                // right: element.right + window.pageXOffset,
                // bottom: element.bottom + window.pageYOffset,
                left: element.left + window.pageXOffset
            };
            if(state === true){
                setPortfolioData({...data});
                setstylePop({top: tt.top+30, left: tt.left, display: "block"});
            }else{
                // if((stylePop.top + 50) < tt.top){
                    setstylePop({display: "none"});
                    setPortfolioData({});
                // }
            }
            // return false;
        }catch(error){
            error_api_call({error: `portfolio/landing|getPopupPos|${error}`});
        }
    }
    //Open Share Modal
    // const openShareModal = (event, url) => {
    //     try{
    //         event.preventDefault();
    //         console.log('daaaaaaaaaa')
    //     }catch(error){
    //         error_api_call({error: `portfolio/landing|openShareModal|${error}`});
    //     }
    // }
    // Toggle Modal
    const toggleModal = (e, modal_type, toggleState, data={}) => {
        try{
            if(toggleState){
                e.preventDefault();
            }
            if(modal_type === 'share'
                && toggleState === true
            ){
                setPortfolioData({...data});
            }
            setModalToggle({...modalToggle, [modal_type]: toggleState});
        }catch(error){
            console.error(error)
            error_api_call({error: `portfolio/landing|toggleModal|${error}`});
        }
    }
    //Like-Unlike Portfolio
    const likePortfolio = async(e, user_id) => {
        try{
            e.preventDefault();
            // const target = e.target;
            // target.disabledlistedDataState = true;
            var dataIntState = {...listedDataState};
            dataIntState[user_id] = {loader: true};
            setListedData({...dataIntState});
            //Call API
            const likeAPI = await post_api_call('port/action/like', {user_id});
            dataIntState = {...listedDataState};
            if(likeAPI.status === 200){
                //Update the like count
                listedDataState[user_id].like_count = listedDataState[user_id].like_count + 1;
                listedDataState[user_id].self_like = 1;
            }
            // else{
            //     alertResponseMessage({alert_type: 2, message: "Error Processing Request! Try after some times"});
            // }
            listedDataState[user_id].loader = false;
            setListedData({...dataIntState});
        }catch(error){
            setListedData({...listedDataState, [user_id]: {loader: false}});
            console.error(error)
            alertResponseMessage({alert_type: 2, message: "Error Processing Request! Try after some times"});
            error_api_call({error: `portfolio/landing|likePortfolio|${error}`});
        }
    }
    //Suffle the portfolio
    const portShuffle = (data=null) => {
        try{
            // console.log(data)
            //Get Image and non-image Data
            // const imgData = [];
            // const nonImgData = [];
            // const arr = data ? [...data] : [...studentData];
            const arr = [...studentData];
            if(arr.length){
                // data.forEach(stu => {
                //     if(stu.pic_name){
                //         imgData.push(stu);
                //     }else{
                //         nonImgData.push(stu);
                //     }
                // });
                // for (let i = imgData.length - 1; i > 0; i--) {
                //     const j = Math.floor(Math.random() * (i + 1));
                //     [imgData[i], imgData[j]] = [imgData[j], imgData[i]];
                // }
                // for (let i = nonImgData.length - 1; i > 0; i--) {
                //     const j = Math.floor(Math.random() * (i + 1));
                //     [nonImgData[i], nonImgData[j]] = [nonImgData[j], nonImgData[i]];
                // }
                // console.log(arr, 'jjjjj');
                // return arr;
                for (let i = arr.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [arr[i], arr[j]] = [arr[j], arr[i]];
                }
                // setStudentData([...imgData, ...nonImgData]);
                setStudentData([...arr]);
            }
        }catch(error){
            // return arr;
            console.error(error)
            error_api_call({error: `portfolio/landing|portShuffle|${error}`});
        }
    }
  
    return (
        <>
        <main id="main">
        <section className={`team-sec pt-0 student-sec mt-up ${!(localStorage.getItem("token") && userReduxData) ? 'overlay-main' : ''}`}>
            <div id="popp" className="popp" style={stylePop}>
                <div className="stu-popp">
                    <div className="stu-img">
                        <img src={mPortfolioData.logo} alt={`${mPortfolioData.first_name} ${mPortfolioData.last_name || ''}`}/>
                    </div>
                    <div className="stu-info">
                        <p className="mb-0 fw-bold">{mPortfolioData.first_name} {mPortfolioData.last_name || ''}</p>
                        <span className="fw-lighter fs-6">{mPortfolioData.class_name}</span>
                        <p className="mb-0 country mt-0 fw-lighter">
                        {mPortfolioData.city || ''}{mPortfolioData.city ? `, ${(mPortfolioData.state).toLowerCase()}` : ''}{mPortfolioData.state ? `, ${mPortfolioData.country}` : ''}
                        </p>
                    </div>
                </div>
                <div>
                    {mPortfolioData.position || ''}
                    {(mPortfolioData.headline || mPortfolioData.achievement || mPortfolioData.passion)
                        &&
                        <div className="fw-lighter three-line">
                            {mPortfolioData.headline} {mPortfolioData.achievement} {mPortfolioData.passion}
                        </div>
                    }
                </div>
            </div>
            {!(localStorage.getItem("token") && userReduxData) &&(
                <>
                <div className="screen-overlay" style={{opacity: 0.8}}></div>
                {/* <div className="row">
                <div className="col-md-6 col-12 col-sm-8"> */}
                <div className="div-overlay-center div-overlay-cen-visible text-white">
                    <div className="text-center head-port m-3">
                        <p className="mb-3 fs-5">Ready to unlock bigger opportunities?</p>
                        <p>PORT transforms your achievements and certificates into a captivating digital portfolio—while EVA expertly guides your future—career choices, college applications and personal growth. Start now!</p>
                        <p>Login to View Details</p>
                        <a href="/login?redirect_uri=/port"><button className="btn btn-primary bg-color-blue">Login to PORT</button></a>
                    </div>
                </div>
                {/* </div>
                </div> */}
            </>
            )}
            
          <div className={`container-fluid container-lg pe-lg-5 ps-lg-5 ${!(localStorage.getItem("token") && userReduxData) ? 'blur-screen' : ''}`}>
                {/* <HubListScrollComp /> */}
              <div className="section-header section-header-1">
                  <h1 className="title mb-4 mt-2 mt-md-0">Portfolio</h1>
              </div>
              <div className="mb-3 p-2" style={{backgroundColor: '#f8f9fa', borderRadius: 5,position: 'relative', zIndex: 99}}>
              {/* ms-3 */}
                {/* <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                        <div className="input-group mb-3">
                            <input className="form-control" placeholder="Search by Name"/>
                            <div className="input-group-append">
                                <span className="input-group-text" style={{height: 38}}><i className="fa fa-search"></i></span>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-5 col-sm-12">
                        <div className="input-group">
                            <input className="form-control" placeholder="Search by Name" value={filterData.name} onChange={(e) => updateInput('input', 'name', e.target.value)}/>
                            <div className="input-group-append">
                                <button className="input-group-text" style={{height: 38}} onClick={(e) => searchPortfolio(e)}><i className="fa fa-search"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 pt-2 pt-md-0">
                        <Select
                            options={[{id: '', school_name: 'ALL'}].concat(schoolData)} 
                            // defaultValue={filterData.school_id}
                            value={filterData.school_id}
                            placeholder={"School/College/Organisation"}
                            onChange={(value) => updateInput('select-react', 'school_id', value, 'school_name')}
                            getOptionLabel ={(option) => option.school_name}
                            getOptionValue ={(option) => option.id}
                            menuPlacement="auto"
                            menuPosition={'fixed'}
                            styles={RSstandardStyle}
                        />
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 pt-2 pt-md-0">
                        {/* <div className="w-110p"> */}
                            <Select
                                options={[{id: '', class_name: 'ALL'}].concat(classData)}
                                value={filterData.class_id}
                                defaultValue={filterData.class_id}
                                placeholder={"Class/Course"}
                                onChange={(value) => updateInput('select-react', 'class_id', value, 'class_name')}
                                getOptionLabel ={(option) => option.class_name}
                                getOptionValue ={(option) => option.id}
                                menuPlacement="auto"
                                menuPosition={'fixed'}
                                styles={RSstandardStyle}
                            />
                        {/* </div> */}
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 pt-2 pt-md-0">
                        {/* <div className="w-200p"> */}
                            <Select
                                options={[{id: '', title: 'ALL'}].concat(passionData)}
                                value={filterData.skill_id}
                                defaultValue={filterData.skill_id}
                                placeholder={"Competencies"}
                                onChange={(value) => updateInput('select-react', 'skill_id', value, 'title')}
                                getOptionLabel ={(option) => option.title}
                                getOptionValue ={(option) => option.id}
                                styles={RSstandardStyle}
                                menuPlacement="auto"
                                menuPosition={'fixed'}
                            />
                        {/* </div> */}
                    </div>
                    {/* <div className="col-lg-3 col-md-3 col-sm-12">
                    <button className="search-btn" onClick={(event) => searchPortfolio(event)}>Search</button>
                    </div> */}
                </div>
              </div>
              <div className="row justify-content-center">
                <div className={`col-md-12 col-12 col-sm-12`}>
                    <div className="row">
                    {typeof ownerPortData.first_name !== "undefined" && (
                        <>
                        <div className="col-md-3 col-12 col-sm-4">
                            <div className="mb-4">   
                                <div className="img-container">
                                    {/* Image */}
                                    <a href={`/port/${ownerPortData.user_name}`}
                                        // target="_blank" rel="noopener noreferrer"
                                    >
                                    <div className="image-wrap">
                                        <img src={ownerPortData.logo} alt={`${ownerPortData.first_name} ${ownerPortData.last_name || ''}`}/>
                                    </div>
                                    </a>
                                    {/* Like Share Button */}
                                    <div className=" share-overlay-active share-overlay p-0">
                                    <div className="share-content">
                                        <ul className="justify-content-between">
                                            <li className="m-1 push-icon">
                                                {/* <button className="btn share-btn" disabled={true}> */}
                                                    <PushPinSvg />
                                                {/* </button> */}
                                            </li>
                                            <li className="p-2">
                                                <a
                                                    href={`/port/edit`}
                                                    // target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                <button className="btn share-btn" title="Edit Portfolio">
                                                    <i className="fa fa-edit text-black"></i>
                                                </button>
                                                </a>
                                            </li>
                                            {/* <li>
                                                <button className="btn active" title="Liked" disabled={false}>
                                                    <HeartSvg />
                                                </button>
                                            </li> */}
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                                <div className="details-container d-flex">
                                    <div className="user-detail float-start">
                                        <a
                                            href={`/port/${ownerPortData.user_name}`}
                                            // target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                        <div>
                                        <span className="display-name"
                                            // onMouseOver={(event)=>getPopupPos(event, true, ownerPortData)}
                                            // onMouseLeave={(event) => getPopupPos(event, false)}
                                        >{`${ownerPortData.first_name} ${ownerPortData.last_name || ''}`}</span>
                                        {ownerPortData.position && (<span className="position">{ownerPortData.position}</span>)}
                                        </div>
                                        </a>
                                    </div>
                                    <div className="stats-container float-end">
                                        <div className="stats-icon" title="Like">
                                            <button className="btn disabled" title="Liked" disabled={true}>
                                                <HeartSvg />
                                            </button>
                                            <span className="stats-count">
                                                {ownerPortData.port_like_count||0}
                                                {/* {(typeof listedDataState[student.id] !== "undefined"
                                                && typeof listedDataState[student.id].like_count !== "undefined")
                                                ? numShortForm(listedDataState[student.id].like_count)
                                                : 0} */}
                                            </span>
                                        </div>
                                        <div className="stats-icon" title="Views">
                                            <EyeSvg />
                                            <span className="stats-count">{ownerPortData.port_view ? numShortForm(ownerPortData.port_view.view_count) : 0}</span>
                                        </div>
                                        <div className="stats-icon" title="Views">
                                            <button className="btn share-btn" title="Share" onClick={(event) => toggleModal(event, 'share', true, ownerPortData)}>
                                                <ShareSvg />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </>
                    )}
					{pageLoading 
					?
                        <div className="col-md-9 col-12 col-sm-8">
                            <div className="text-center mt-5">
                                <LoaderMedium size={65} />
                            </div>
                        </div>
					:
						studentData.length > 0 ?
							<>
                            {/* <div className="row"> */}
								{studentData.map((student, stu_indx) => (
                                     <div className="col-md-3 col-12 col-sm-4" key={`stu-data${stu_indx}`}>
                                        <div className="mb-4">   
                                            {/* <h2>
                                            <a href={`/port/${student.user_name}`} target="_blank" rel="noopener noreferrer">
                                                <strong>{student.first_name} {student.last_name || ''}</strong>
                                            </a>
                                            <span className="position">{student.position}</span>
                                            
                                            </h2> */}
                                            <div className="img-container">
                                                {/* Image */}
                                                <a href={`/port/${student.user_name}`}
                                                    // target="_blank" rel="noopener noreferrer"
                                                >
                                                <div className="image-wrap">
                                                    <img src={student.logo} alt={`${student.first_name} ${student.last_name || ''}`}/>
                                                </div>
                                                </a>
                                                {/* Like Share Button */}
                                                <div className="share-overlay">
                                                <div className="share-content">
                                                    <ul>
                                                        <li>
                                                            <button className="btn share-btn" title="Share" onClick={(event) => toggleModal(event, 'share', true, student)}>
                                                                <ShareSvg />
                                                            </button>
                                                        </li>
                                                        <li>
                                                        {(listedDataState[student.id] && listedDataState[student.id].self_like)
                                                            ?
                                                                <button className="btn active" title="Liked" disabled={true}>
                                                                    <HeartSvg />
                                                                </button>
                                                            :
                                                                <>
                                                                {(typeof listedDataState[student.id] !== "undefined"
                                                                    && typeof listedDataState[student.id].loader !== "undefined"
                                                                    && listedDataState[student.id].loader)
                                                                    ?
                                                                        <button className="btn" title="Like" disabled={true}>
                                                                            <LoaderMedium
                                                                                size={16}
                                                                            />
                                                                        </button>
                                                                    :   
                                                                    <button className="btn" title="Like" onClick={(event) => likePortfolio(event, student.id)}>
                                                                       <HeartSvg />
                                                                    </button>
                                                                }
                                                                </>
                                                            }
                                                        </li>
                                                    </ul>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="details-container d-flex">
                                                <div className="user-detail float-start">
                                                    <a
                                                        href={`/port/${student.user_name}`}
                                                        // target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                    <div>
                                                    <span className="display-name"
                                                        onMouseOver={(event)=>getPopupPos(event, true, student)}
                                                        onMouseLeave={(event) => getPopupPos(event, false)}
                                                    >{`${student.first_name} ${student.last_name || ''}`}</span>
                                                    {student.position && (<span className="position">{student.position}</span>)}
                                                    </div>
                                                    </a>
                                                </div>
                                                <div className="stats-container float-end">
                                                    <div className="stats-icon" title="Like">
                                                        {(listedDataState[student.id] && listedDataState[student.id].self_like)
                                                            ?
                                                                <button className="btn active" title="Liked" disabled={true}>
                                                                   <HeartSvg />
                                                                </button>
                                                            :
                                                                <>
                                                                {(typeof listedDataState[student.id] !== "undefined"
                                                                    && typeof listedDataState[student.id].loader !== "undefined"
                                                                    && listedDataState[student.id].loader)
                                                                    ?
                                                                        <button className="btn" title="Like" disabled={true}>
                                                                            <LoaderMedium
                                                                                size={16}
                                                                            />
                                                                        </button>
                                                                    :   
                                                                    <button className="btn" title="Like" onClick={(event) => likePortfolio(event, student.id)}>
                                                                            <HeartSvg />
                                                                        </button>
                                                                }
                                                                </>
                                                        }
                                                        <span className="stats-count">
                                                            {(typeof listedDataState[student.id] !== "undefined"
                                                            && typeof listedDataState[student.id].like_count !== "undefined")
                                                            ? numShortForm(listedDataState[student.id].like_count)
                                                            : 0}</span>
                                                    </div>
                                                    <div className="stats-icon" title="Views">
                                                        <EyeSvg />
                                                        <span className="stats-count">{student.port_view ? numShortForm(student.port_view.view_count) : 0}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            {/* </div> */}
								{showStuLoader && (localStorage.getItem("token") && userReduxData) && 
									<div className="text-center">
										<div ref={studentLoader}>
											<LoaderMedium />
										</div>
										<button className="btn btn-primary" ref={loadMoreFellow} onClick={() => {loadMore()}}>Load More</button>
									</div>
								}
							</>
						: 
						<div className="col-md-9 col-12 col-sm-8">
							<div className="no-fellow mt-4">
								<img src={noStuImg} alt="No Matching Portfolio Found" />
								<p className="pt-5 head">Sorry, no matching Portfolio found!</p>
								<p className="sub-title ml-2 mr-2">Please try with different filter</p>
							</div>
						</div>
					}
                    </div>
				</div>
              </div>
          </div>
        </section>
        </main>
        {/* For Showing Share Info */}
        <ModalSharePort
            isShow={modalToggle.share}
            toggleModal={(e, state) => toggleModal(e, 'share', state)}
            // loader={hubInfoLoading}
            data={mPortfolioData}
            display_type={(ownerPortData
                            && ownerPortData.id
                            && ownerPortData.id === mPortfolioData.id)
                            ? 'own'
                            :'others'}
        />
        </>
    )    
}

export default PortfolioMain;